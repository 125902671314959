<template>
    <CCol sm="6" md="4" xl="2" class="mb-3">
  <CCard>
      <CCardHeader>{{ screen.name }}</CCardHeader>
      <CCardBody>
        <p>Texte bandeau : {{ screen.bandeau }}</p>
        <p v-if="screen.online"><span class="text-success">online</span></p>
        <p v-else><span class="text-danger">offline</span></p>
       </CCardBody>
       <CCardFooter align="right">
           
             <router-link :to="{ name: 'Screen', params: { id: screen.id } }">configurer</router-link>
           
        </CCardFooter> 
  </CCard>
    </CCol>
</template>

<script>
export default {
  name: "ScreenCard",
  props: {
    screen: {
      type: Object,
    },
  },
};
</script>

<style scoped>
.card{
   height: 100%;
}
</style>
