<template>
  <CRow>
    <ScreenCard v-for="s in screens" :screen="s" :key="s.id"></ScreenCard> 
  </CRow>
</template>

<script>
import ScreenCard from '@/components/ScreenCard.vue'
export default {
  name: "Dashboard",
  components:{
      ScreenCard
  },
  data(){
      return{
          screens: null
      }
  },
  created(){
      let self = this;
      window.axios.get('/api/screens').then((rep) => {
          self.screens = rep.data.screens
          console.log(self.screens)
      });
  },
  methods: {
      add(){
          this.screens.push({id: 333, name: "test", bandeau: "dddddd"})
          console.log('ADD')
          console.log(this.screens)
      }
  }
};
</script>
